import React from 'react'
import "./PPBanner.css"

function PPBanner() {
  return (<>    <div className='PPBanner'>
      {/* <div className='banner-img'>
        <img src={require("../../images/categoryBanner.jpg")} alt='bannerimg' />
      </div> */}
      <div className='banner-text'>
      <h2>The Best 
Hotels Deals</h2>
      <p>Lorem ipsum dolor sit amet, consectad minim veniam, 
quis nostrud exercitatio</p>
      </div>
    </div>
    </>

  )
}

export default PPBanner
