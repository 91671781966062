import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import "./Navbar.css";
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

function Navbar() {
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const navigate = useNavigate();

  function sendToExplore() {
    navigate("/explore");
  }
  function sendToHome() {
    navigate("/");
  }
  function sendToLogin() {
    navigate("/userlogin")
  }
  function sendToVendor() {
    navigate("/vendor")
  }
  function sendToVendorLogin(){
    navigate("/vendorlogin")
  }
  // const sendToUser = () => {
  //   console.log("Clicked")
  // }

  const handleClick = () => {
    setNavbar(!navbar);
  }

  const MouseOver = () => {
    setOpen(true);
  };

  const MouseOut = () => {
    setOpen(false);
  };

  function toggleSearchBar() {
    setSearchBar(!searchBar);
  }


  return (
    <div className='navbar'>
      <div className='navbar-top'>

        <div className={searchBar?"input-box open":"input-box"}>
          <input type="text" placeholder="Search..." />
          <span className="search" onClick={toggleSearchBar}>
            <SearchIcon className='search-icon' />
          </span>
          <CloseIcon className='close-icon' onClick={toggleSearchBar}/>
        </div>


        <MenuIcon className='menuIcon' onClick={handleClick} />
        <h2>LOGO</h2>
        <button className='btn btn-outline-secondary contact-us'>Contact Us</button>
        <div className='dropdown' onMouseEnter={MouseOver} onMouseLeave={MouseOut} >
          <button type='button' className='btn btn-primary login-btn1'>Login</button>
          {open ? (
            <ul className="menu" >
              <li className="menu-item" >
                <button onClick={sendToLogin} ><LoginIcon fontSize='small' /> Login as User</button>
              </li>
              <li className="menu-item">
                <button onClick={sendToVendorLogin}><LoginIcon fontSize='small' /> Login as Vendor</button>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
      <div className={navbar ? "navbar-bottom mobile" : "navbar-bottom"} style={navbar ? { display: "flex" } : {}}  >
        <div className={navbar ? "navbar-bottomItems mobile" : "navbar-bottomItems"} >
          <h5 onClick={sendToHome}>Home</h5>
          <h5 >About</h5>
          <h5 onClick={sendToVendor}>Vendor</h5>
          <h5 onClick={sendToExplore}>Explore</h5>
          {navbar ? (
          <div className={searchBar?"input-box open":"input-box open"}>
          <input type="text" placeholder="Search..." />
          <span className="search" onClick={toggleSearchBar}>
            <SearchIcon className='search-icon' />
          </span>
          <CloseIcon className='close-icon' onClick={toggleSearchBar}/>
        </div>
        ):null}
        </div>
        <div className='dropdown' onMouseEnter={MouseOver} onMouseLeave={MouseOut}>
          <button type='button' className='btn  login-btn'>Login</button>
          {open ? (
            <ul className="menu" >
              <li className="menu-item">
                <button onClick={sendToLogin}><LoginIcon fontSize='small' /> Login as User</button>
              </li>
              <li className="menu-item">
                <button onClick={sendToVendorLogin}><LoginIcon fontSize='small' /> Login as Vendor</button>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Navbar
