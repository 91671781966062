import React from 'react'
import "./Banner.css"

function Banner() {
  return (
    <div className='banner'>
        <h1>What's your plan <span>today</span> ?</h1>
        <p>Find out perfect place to hangout in your city from over 1258 listings</p>
        <form className='landingPage-form'>
            <input type='text' id='what' placeholder='What ?'/>
            <input type='text' placeholder='Where ? '/>
            <button type='submit' >Search</button>
        </form>
    </div>
  )
}

export default Banner
